<template>
  <div class="admin-view-container">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="100px"
      :model="postData"
      :rules="rules"
      size="small"
      @submit.native.prevent="handleSubmit"
    >
      <el-form-item label="用户名" prop="adminLoginName">
        <el-input
          v-model="postData.adminLoginName"
          placeholder="请填写用户名"
          auto-complete="off"
          maxlength="20"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="!isEdit" label="登录密码" prop="adminLoginPwd">
        <el-input
          type="password"
          v-model="postData.adminLoginPwd"
          placeholder="请填写密码"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="adminMobile">
        <el-input
          v-model="postData.adminMobile"
          placeholder="请填写手机号"
          auto-complete="off"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item label="管理员名称" prop="adminName">
        <el-input
          v-model="postData.adminName"
          placeholder="请填写管理员名称"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="adminSex">
        <el-radio-group v-model="postData.adminSex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
          <el-radio :label="0">未知</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="角色类型" prop="roleType">
        <el-radio-group v-model="postData.roleType">
          <!-- <el-radio :label="1">管理员</el-radio> -->
          <el-radio :label="2">街道办事处</el-radio>
          <el-radio :label="3">社区居委会</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit" :loading="loading"
          >提交</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAdmin, adminInfo } from "@/api/index.js";
import md5 from "@/utils/md5.js";
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      postData: {
        adminId: "",
        adminLoginName: "", //登录用户名
        adminLoginPwd: "",
        adminMobile: "",
        adminName: "", //管理员名称
        adminSex: 0, //1 男 2 女 0 未知
        roleType: 3, //角色类型 1 管理员 2 街道办事处 3 社区居委会
      },
      isEdit: false,
      rules: {
        adminLoginName: [
          { required: true, trigger: "change", message: "请填写登录用户名" },
        ],
        adminLoginPwd: [
          { required: true, trigger: "change", message: "请填写密码" },
        ],
        adminMobile: [
          { required: true, trigger: "change", message: "请填写手机号" },
          { trigger: "change", validator: this.$rules.phone },
        ],
        adminName: [
          { required: true, trigger: "change", message: "请填写管理员名称" },
        ],
        adminSex: [
          { required: true, trigger: "change", message: "请选择性别" },
        ],
        roleType: [
          { required: true, trigger: "change", message: "请选择管理员类型" },
        ],
      },
    };
  },
  created() {
    if (this.$props.row) {
      this.isEdit = true;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      adminInfo({ id: this.$props.row.adminId }).then((res) => {
        if (res.data.code == 200) {
          let datas = res.data.data;
          Object.assign(this.postData, datas);
          this.postData.adminSex = Number(datas.adminSex);
          this.postData.roleType = Number(datas.roleType);
        }
      });
    },
    cancel() {
      this.$emit("end");
    },
    handleSubmit() {
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postDatas = JSON.parse(JSON.stringify(this.postData));
          postDatas.adminLoginPwd = md5(postDatas.adminLoginPwd, 32);
          if (!this.isEdit) {
            postDatas.adminId = null;
            delete postDatas.adminId;
          } else {
            delete postDatas.adminLoginPwd;
          }
          this.$utils.resolveRequest(addAdmin(postDatas), this);
        } else {
          this.loading = false;
          this.$message.warning("请填写正确信息！");
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
